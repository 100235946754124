@import '../foundation/Responsive.scss';

.Case {
    position: fixed;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    z-index: 90000000000;
    @include for-size(medium-up) {
        // display: none;
        // position: relative;
        bottom: 10px;
        right: 20px;
        width: 350px;
        height: 500px;
        max-height: 500px;
    }
}

.Case-header {
    flex: 1;
    flex-direction: column;
    display: inline-flex;
    @include for-size(medium-up) {
        background: transparent;
    }
}

.Case-title {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    @include for-size(medium-up) {
        visibility: hidden;
        display: none;
    }
}

.Case-box {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #fff;
    border-top-left-radius: 34px;
    border-top-right-radius: 34px;
    padding: 34px 32px 20px 32px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    @include for-size(medium-up) {
        // border-bottom-left-radius: 38px;
        // border-bottom-right-radius: 38px;
        box-shadow: 0px 0px 15px #909090;
        max-height: 450px;
    }
}

.Case-box-close-button {
    visibility: hidden;
    display: none;
    @include for-size(medium-up) {
        cursor: pointer;
        visibility: unset;
        display: unset;
        background-color: #ffffff;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        position: absolute;
        right: 10px;
        top: 10px;
        text-align: center;
        // box-shadow: 0px 0px 15px #909090;
    }
}

.Case-box-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.Case-box-content-box {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.Case-box-title {
    visibility: hidden;
    display: none;
    font-size: 20px;
    @include for-size(medium-up) {
        visibility: unset;
        display: unset;
        color: #707070;
        padding-bottom: 25px;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-color: #c0c0c0;
        margin-left: -34px;
        margin-right: -34px;
        padding-left: 10px;
        padding-right: 34px;
    }
}

.Case-input {
    font-size: 17px;
    min-height: 20px;
    max-height: 100px;
    overflow: hidden;
    width: 100%;
    color: #707070;
}
.Case-input[placeholder]:empty:before {
    content: attr(placeholder);
    color: #c0c0c0;
}

.Case-event-attachment-backdrop {
    height: 0px;
    width: 0px;
    cursor: pointer;
}

.Case-event-attachment-backdrop.zoom {
    background-color: black;
    opacity: 0.9;
}

.Case-event-attachment-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    cursor: pointer;
}

.Case-event-attachment-container.zoom {
    height: 100%;
    width: 100%;
}

.Case-event-attachment {
    height: 100%;
    width: 100%;
    object-fit: contain;
    cursor: pointer;
}

.Case-event-attachment.glow {
    height: 90%;
    width: 90%;
    -webkit-filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.5));
    filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.5));
}

.zoom {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
}
